import { RootStore } from './index';

import {
  configure,
  observable,
  decorate,
  action,
  runInAction,
  computed,
} from 'mobx';

import {
  User,
  AccessTokenData,
  UserRole,
  ReportRequest,
  ManagerPerformance,
  EmployeePerformance,
  FormErrors,
  EvaluationReport,
} from '../types';

import {
  setAccessTokenData,
  clearAuthData,
  setUser,
  getUser,
  setRole,
  getRole,
} from '../services/AuthRepository';

import { api } from '../services/http';
import { getFileName } from '../utils/functions';
import { AxiosError, AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

configure({ enforceActions: 'observed' });

export const EMPLOYEE_PERFORMANCE = 'EMPLOYEE_PERFORMANCE';
export const EMPLOYEE_PERFORMANCE_DOWNLOAD = 'EMPLOYEE_PERFORMANCE_DOWNLOAD';
export const FETCH_USER = 'FETCH_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const MANAGER_PERFORMANCE = 'MANAGER_PERFORMANCE';
export const MANAGER_PERFORMANCE_DOWNLOAD = 'MANAGER_PERFORMANCE_DOWNLOAD';
export const PASSWORD_RECOVERY = 'PASSWORD_RECOVERY';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const STANDARDS_EVALUATION = 'STANDARDS_EVALUATION';
export const STANDARDS_EVALUATION_DOWNLOAD = 'STANDARDS_EVALUATION_DOWNLOAD';
export const TOGGLE_EMAIL_NOTIFICATIONS = 'TOGGLE_EMAIL_NOTIFICATIONS';

export interface IUserStore {
  user: User;
  // @todo this should be typed as UserRole (without null) after
  // https://github.com/e-spres-oh/impacthr-ui/issues/71
  role: UserRole | null;
  isAuthenticated: () => boolean;
  isInitialized: boolean;
  init(user: User): Promise<void>;
  login(credentials: UserLoginPayload): Promise<void>;
  logout(): void;
  ssoLogin(authToken: string): Promise<void>;
  sendPasswordRecoveryLink(payload: PasswordRecoveryPayload): Promise<void>;
  resetPassword(payload: PasswordResetPayload): Promise<void>;
  changePassword(payload: PasswordChangePayload): Promise<void>;
  setCurrentRole(role: UserRole): void;
  getCurrentRole(): UserRole | null;
  changeRole(role: UserRole): void;
  updatePassword(password: string, userId?: number): Promise<void>;
  toggleEmailNotifications(
    userId: number,
    notificationEnabled: boolean,
  ): Promise<void>;
  getStandardsEvaluation(request: ReportRequest): Promise<EvaluationReport[]>;
  getEmployeePerformance(request: ReportRequest): Promise<EmployeePerformance[]>;
  getManagerPerformance(request: ReportRequest): Promise<ManagerPerformance[]>;
  downloadEmployeePerformance(request: ReportRequest): Promise<void>;
  downloadManagerPerformance(request: ReportRequest): Promise<void>;
  downloadStandardsEvaluation(request: ReportRequest): Promise<void>;
  fetchUser(user_id: number): Promise<User|undefined>;

  hasManagerRole: boolean;
  hasEmployeeRole: boolean;
  hasAdminRole: boolean;
  hasSuperRole: boolean;
  isManager: boolean;
  isEmployee: boolean;
  isAdmin: boolean;
  isSuper: boolean;
}

export const EMPTY_USER: User = {
  id: 0,
  manager_id: 0,
  client_id: 0,
  name: '',
  email: '',
  roles: [],
  notification_enabled: false,
};

export default class UserStore implements IUserStore {
  stores: RootStore;

  user = EMPTY_USER;
  isInitialized = false;
  role = getRole();

  constructor(stores: RootStore) {
    this.stores = stores;
    this.authenticate();
  }

  async init(user: User) {
    const { clientStore, employeeStore, evaluationPeriodStore } = this.stores;

    try {
      // add here everything you need to be eagerly loaded
      await Promise.all([
        evaluationPeriodStore.fetchTimeFrames(),
        clientStore.fetchTimeFrames(),
        clientStore.fetchUsers(),
        employeeStore.fetchEmployees(user),
      ]);

      runInAction(() => {
        this.isInitialized = true;
      });
    } catch (e) {
      console.warn('Unable to INIT_USER', e);
    }
  }

  isAuthenticated() {
    const currentUser = getUser();

    return !!currentUser?.id;
  }

  async authenticate() {
    // We only need to init stuff if we are authenticated
    // Otherwise we will get into 401 loops
    if (this.isAuthenticated()) {
      this.user = getUser();

      await this.init(this.user);
    } else {
      this.isInitialized = true;
    }
  }

  async handleLogin(access_token_data: AccessTokenData)
  {
    setAccessTokenData(access_token_data);

    const current_user = await getCurrentUser();

    setUser(current_user);

    this.setCurrentRole('employee');

    if (current_user.roles?.includes('manager')) {
      this.setCurrentRole('manager');
    }

    await this.stores.clientStore.fetchClientDetails(current_user.client_id);

    runInAction(() => { this.user = current_user });

    await this.init(this.user);
  }

  async ssoLogin(authToken: string) {
    this.stores.loadingStore.start(LOGIN_USER);

    try {
      const access_token_data = await getAccessTokenFromGSuite(authToken);
      this.handleLogin(access_token_data);
    } catch (error) {
      console.warn(error);
      throw new Error('Invalid credentials. Please try again!');
    } finally {
      this.stores.loadingStore.end(LOGIN_USER);
    }
  }

  async login(credentials: UserLoginPayload) {
    this.stores.loadingStore.start(LOGIN_USER);

    try {
      const access_token_data = await getAccessTokenData(credentials);
      await this.handleLogin(access_token_data);
    } catch (error) {
      console.warn(error);
      throw new Error('Invalid credentials. Please try again!');
    } finally {
      this.stores.loadingStore.end(LOGIN_USER);
    }
  }

  async sendPasswordRecoveryLink(payload: PasswordRecoveryPayload) {
    this.stores.loadingStore.start(PASSWORD_RECOVERY);

    const response = await api.post('/auth/recoverpassword', payload)

    this.stores.loadingStore.end(PASSWORD_RECOVERY);

    if (response === undefined)
      throw new Error('Could not send an email');
  }

  async resetPassword(payload: PasswordResetPayload) {
    this.stores.loadingStore.start(PASSWORD_RESET);

    await api.post('auth/resetpassword', payload)
      .catch(({ response }) => {
        throw new Error(response.data.error);
      });

    this.stores.loadingStore.end(PASSWORD_RESET);
  }

  async changePassword(payload: PasswordChangePayload): Promise<void> {
    this.stores.loadingStore.start(PASSWORD_RESET);

    try {
      await api.post('newpassword', payload);
      this.stores.notificationStore.triggerSuccessNotification('Password successfully changed!');
    } catch (error) {
      const { status, data } = (error as AxiosError)?.response || {};

      if (status === 422) {
        const errors = Object.values((data as FormErrors).errors).flat();
        errors.forEach((error) => {
          this.stores.notificationStore.triggerErrorNotification(error);
        });
      }
    } finally {
      this.stores.loadingStore.end(PASSWORD_RESET);
    }
  }

  async updatePassword(password: string, userId?: number)
  {
    this.stores.loadingStore.start(PASSWORD_RESET);

    try {
      if (userId === undefined)
      {
        userId = this.user.id
      }

      const { data } = await api.post(`users/${userId}/resetpassword`, { password })

      return data
    }

    catch (error)
    {
      console.warn(error);
    }

    finally
    {
      this.stores.loadingStore.end(PASSWORD_RESET);
    }
  }

  async changeRole(role: UserRole) {
    this.setCurrentRole(role);
    await this.init(this.user);
  }

  async toggleEmailNotifications(userId: number, notificationEnabled: boolean) {
    this.stores.loadingStore.start(TOGGLE_EMAIL_NOTIFICATIONS);
    try {
      const user = await toggleNotifications(userId, notificationEnabled);

      this.stores.notificationStore.triggerSuccessNotification(
        'Settings successfully saved!',
      );

      runInAction(() => {
        setUser(user);
        this.user.notification_enabled = user.notification_enabled;
      });
    } catch (error) {
      console.warn(error);
      this.stores.notificationStore.triggerErrorNotification(
        'Something went wrong!',
      );
    } finally {
      this.stores.loadingStore.end(TOGGLE_EMAIL_NOTIFICATIONS);
    }
  }

  async getStandardsEvaluation(request: ReportRequest): Promise<EvaluationReport[]> {
    this.stores.loadingStore.start(STANDARDS_EVALUATION);

    try {
      const { data } = await api.post('reports/standards-evaluations', request);
      return data.data;
    } catch (error) {
      this.stores.notificationStore.triggerErrorNotification('Could not retrieve data');
    } finally {
      this.stores.loadingStore.end(STANDARDS_EVALUATION);
    }

    return [];
  }

  async getEmployeePerformance(request: ReportRequest): Promise<EmployeePerformance[]> {
    this.stores.loadingStore.start(EMPLOYEE_PERFORMANCE);

    try {
      const { data } = await api.post('reports/employee-performance', request);
      return data;
    }

    catch (error) {
      this.stores.notificationStore.triggerErrorNotification('Could not retrieve data');
    }

    finally {
      this.stores.loadingStore.end(EMPLOYEE_PERFORMANCE);
    }

    return [];
  }

  async getManagerPerformance(request: ReportRequest): Promise<ManagerPerformance[]>
  {
    try
    {
      this.stores.loadingStore.start(MANAGER_PERFORMANCE);
      const { data } = await api.post('reports/manager-performance', request);
      return data;
    }

    catch (error)
    {
      this.stores.notificationStore.triggerErrorNotification(
        'Could not retrieve data'
      );
    }

    finally
    {
      this.stores.loadingStore.end(MANAGER_PERFORMANCE);
    }

    return [];
  }

  async downloadEmployeePerformance(request: ReportRequest): Promise<void> {
      this.stores.loadingStore.start(EMPLOYEE_PERFORMANCE_DOWNLOAD);

      try {
        const { data, headers } = await downloadEmployeePerformance(request);
        const filename = getFileName(headers);
        saveAs(data, filename);
      }

      catch (error) {
        this.stores.notificationStore.triggerErrorNotification(
          'An error occurred while downloading report'
        );
      }

      finally {
        this.stores.loadingStore.end(EMPLOYEE_PERFORMANCE_DOWNLOAD);
      }
  }

  async downloadManagerPerformance(request: ReportRequest): Promise<void>
  {
    try
    {
      this.stores.loadingStore.start(MANAGER_PERFORMANCE_DOWNLOAD);
      const { data, headers } = await downloadManagerPerformance(request);
      const filename = getFileName(headers);
      saveAs(data, filename);
    }

    catch (error)
    {
      this.stores.notificationStore.triggerErrorNotification(
        'An error occurred while downloading report'
      );
    }

    finally
    {
      this.stores.loadingStore.end(MANAGER_PERFORMANCE_DOWNLOAD);
    }
  }

  async downloadStandardsEvaluation(request: ReportRequest): Promise<void> {
    this.stores.loadingStore.start(STANDARDS_EVALUATION_DOWNLOAD);
    try {
      const { data, headers } = await downloadStandardsEvaluation(request);
      const filename = getFileName(headers);
      saveAs(data, filename);
    } catch (error) {
      this.stores.notificationStore.triggerErrorNotification(
        'An error occurred while downloading report'
      );
    } finally {
      this.stores.loadingStore.end(STANDARDS_EVALUATION_DOWNLOAD);
    }
  }

  async fetchUser(user_id: number): Promise<User|undefined>
  {
    if (!user_id) return;

    try {
      this.stores.loadingStore.start(FETCH_USER);
      const user = await fetchUser(user_id);
      return user;
    }

    catch (error)
    {
      this.stores.notificationStore.triggerErrorNotification(
        'An error occurred retrieving the user'
      );
    }

    finally
    {
      this.stores.loadingStore.end(FETCH_USER);
    }
  }

  logout() {
    this.user = EMPTY_USER;
    this.stores.clientStore.reset();
    this.stores.employeeStore.reset();
    this.stores.high5Store.reset();
    this.stores.goalStore.reset();
    this.stores.commentStore.reset();
    this.stores.reviewStore.reset();
    this.stores.taskStore.reset();
    this.stores.notificationStore.reset();
    clearAuthData();
  }

  setCurrentRole(role: UserRole) {
    if (getUser().roles?.includes(role)) {
      setRole(role);
      this.role = role;
    }
  }

  // @todo this is an action, but also returns something?
  getCurrentRole() {
    this.role = getRole() as UserRole;

    return this.role;
  }

  get hasManagerRole() {
    return this.user.roles!.includes('manager');
  }

  get hasEmployeeRole() {
    return this.user.roles!.includes('employee');
  }

  get hasAdminRole() {
    return this.user.roles!.includes('admin');
  }

  get hasSuperRole() {
    return this.user.roles!.includes('super');
  }

  get isManager() {
    return this.role === 'manager';
  }

  get isEmployee() {
    return this.role === 'employee';
  }

  get isAdmin() {
    return this.role === 'admin';
  }

  get isSuper() {
    return this.role === 'super';
  }
}

decorate(UserStore, {
  user: observable,
  role: observable,
  isInitialized: observable,

  hasManagerRole: computed,
  hasEmployeeRole: computed,
  hasAdminRole: computed,
  hasSuperRole: computed,
  isManager: computed,
  isEmployee: computed,
  isAdmin: computed,
  isSuper: computed,

  init: action,
  login: action,
  setCurrentRole: action,
  getCurrentRole: action,
  toggleEmailNotifications: action,
  logout: action,
});

async function getCurrentUser(): Promise<User> {
  const response = await api.post('auth/me', {});
  return response.data;
}

async function fetchUser(user_id: number): Promise<User> {
  const { data } = await api.get(`users/${user_id}`);
  return data;
}

async function getAccessTokenData(
  payload: UserLoginPayload,
): Promise<AccessTokenData> {
  const response = await api.post('auth/login', payload);
  return response.data;
}

async function getAccessTokenFromGSuite(
  token: string,
): Promise<AccessTokenData> {
  const response = await api.post('auth/google', { token: token });

  return response.data;
}

async function toggleNotifications(
  userId: number,
  notificationEnabled: boolean,
): Promise<User> {
  const response = await api.patch(`users/${userId}`, {
    notification_enabled: notificationEnabled,
  });

  return response.data;
}

async function downloadEmployeePerformance(
  request: ReportRequest
): Promise<AxiosResponse> {
  return api.post(
    'reports/employee-performance/download',
    request,
    { responseType: 'blob' }
  );
}

async function downloadManagerPerformance(
  request: ReportRequest
): Promise<AxiosResponse> {
  return api.post(
    'reports/manager-performance/download',
    request,
    { responseType: 'blob' }
  );
}

async function downloadStandardsEvaluation(request: ReportRequest): Promise<AxiosResponse> {
  return api.post(
    'reports/standards-evaluations/download',
    request,
    { responseType: 'blob' }
  );
}

type UserLoginPayload = {
  email: string;
  password: string;
};

type PasswordRecoveryPayload = {
  email: string;
};

type PasswordResetPayload = {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
};

type PasswordChangePayload = {
  current_password: string;
  new_password: string;
};
