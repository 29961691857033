import { ReactNode, useState } from "react"

export default function useFormState() {
  const [error, setErrorMessage] = useState<string|ReactNode>('');

  const [isSuccess, setIsSuccess] = useState(false);

  const [isPending, setIsPending] = useState(false);

  return {
    error,
    isError: error !== '',
    isSuccess,
    isPending,
    setErrorMessage,
    setIsSuccess,
    setIsPending,
  }
}
