import React from 'react';
import { RouteComponentProps } from "react-router-dom";
import { useAdaptiveLayout } from "../../utils/hooks";
import styles from './PasswordReset.module.css';
import { Heading, Icon, IconEnum, Stack } from '../../components';
import PasswordResetForm from './PasswordResetForm';
import base64url from 'base64url';

type RouteParams = {
  encoded: string;
}

type Props = RouteComponentProps<RouteParams>;

interface TokenObject {
  email: string;
  token: string;
}

export default function PasswordReset({ match }: Props) {
  const { isDesktop } = useAdaptiveLayout();

  const { encoded } = match.params;

  const { email, token }: TokenObject = JSON.parse(base64url.decode(encoded));

  return (
    <div data-mobile={!isDesktop} className={styles.container}>
      <div className={styles.loginContainer}>
        <Stack gap={8} align={'center'}>
          <Icon icon={IconEnum.HIGH5} size={36} />

          <Heading>Reset Password</Heading>

          <PasswordResetForm
            email={email}
            token={token}
          />
        </Stack>
      </div>
    </div>
  );
}

