import React, { useState } from "react";
import styles from './PasswordReset.module.css';
import { Button, Input, Spacer, Stack, StrengthMeter, Text } from "../../components";
import { IUserStore } from "../../stores";
import useFormState from "../../hooks/useFormState";
import { usePasswordValidation } from "../../hooks/usePasswordValidation";
import { Link, useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";

interface Props {
  email: string;
  token: string;
  userStore?: IUserStore;
}

function PasswordResetForm({
  email,
  token,
  userStore,
}: Props) {
  const history = useHistory();

  const {
    error,
    isError,
    isPending,
    isSuccess,
    setErrorMessage,
    setIsPending,
    setIsSuccess,
  } = useFormState();

  const [password, setPassword] = useState('');

  const [password_confirmation, setPasswordConfirmation] = useState('');

  const {
    strength,
    validLength,
    match,
    valid,
  } = usePasswordValidation({
    password,
    confirmPassword: password_confirmation,
  });

  if (isSuccess) {
    return (
      <div>
        <Text>Password successfully reset!</Text>

        <Spacer vertical={16} />

        <div className={styles.centered}>
          <Button
            onClick={() => { history.push('/') }}
          >
            Go to Login
          </Button>
        </div>
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className={styles.formContainer}
    >
      <Text>Enter your new password. Must be a minimum of 8 characters</Text>
      <Spacer vertical={32} />

      <Stack gap={16} align={'stretch'}>
        <Input
          onChange={({ target }) => setPassword(target.value)}
          value={password}
          placeholder={'Enter password'}
          type={'password'}
          alignText={'center'}
          error={ password.length > 0 && !validLength ? 'Too few characters' : undefined }
        />

        <StrengthMeter
          value={strength}
          max={4}
          showLabel={!!password}
        />

        <Input
          onChange={({ target }) => setPasswordConfirmation(target.value)}
          value={password_confirmation}
          placeholder={'Confirm password'}
          type={'password'}
          alignText={'center'}
          error={ password_confirmation.length > 0 && !match ? 'Passwords don\'t match' : undefined }
        />
      </Stack>

      <Spacer vertical={32} />

      {isError && (
        <>
          <div className={styles.centered}>
            {error}
          </div>

          <Spacer vertical={32} />
        </>
      )}

      {!isError && (
        <div className={styles.centered}>
          <Button
            disabled={!valid || isPending}
            onClick={() => submit()}
          >
            Reset my password!
          </Button>
        </div>
      )}
    </form>
  );

  async function submit() {
    const payload = {
      email,
      token,
      password,
      password_confirmation,
    }

    setIsPending(true);

    try {
      await userStore!.resetPassword(payload);
      setIsSuccess(true);
    } catch (error) {
      if (error.message === 'This password reset token is invalid.') {
        setErrorMessage(expiredTokenMessage());
      } else {
        setErrorMessage(error.message);
      }
    } finally {
      setIsPending(false);
    }
  }

  function expiredTokenMessage() {
    return (
      <p>
        Your link has expired.<br />Please{' '}
        <Link to="/passwordrecovery" className={styles.link}>request a new one</Link>
      </p>
    )
  }
}

export default inject('userStore')(observer(PasswordResetForm));
