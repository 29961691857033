import { RootStore } from './index';
import { Client, EvaluationPeriod, CheckInCycle, User, ApplicationUsage } from '../types';
import { decorate, observable, action, runInAction, configure } from 'mobx';
import { api } from '../services/http';
import {
  setClient,
  clearClientData,
  getClient,
} from '../services/ClientRepository';
import { EMPTY_USER } from './userStore';
import { AxiosResponse } from 'axios';
import { getFileName } from '../utils/functions';
import { saveAs } from 'file-saver';

configure({ enforceActions: 'observed' });

export const FETCH_CLIENT_DETAILS = 'FETCH_CLIENT_DETAILS';
export const FETCH_CURRENT_TIME_FRAMES = 'FETCH_CURRENT_TIME_FRAMES';
export const FETCH_USERS = 'FETCH_USERS';
export const CREATE_USER = 'CREATE_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const EXPORT_USERS = 'EXPORT_USERS';
export const IMPORT_USERS = 'IMPORT_USERS';
export const APPLICATION_USAGE = 'APPLICATION_USAGE';
export const APPLICATION_USAGE_DOWNLOAD = 'APPLICATION_USAGE_DOWNLOAD';

export type UserEdit = {
  client_id: number;
  manager_id: number | null;
  name: string;
  email: string;
};

export type UserCreate = {
  client_id: number;
  manager_id: number | null;
  name: string;
  email: string;
};

export const EMPTY_EVALUATION_PERIOD: EvaluationPeriod =
{
  id: 0,
  client_id: 0,
  active: false,
  label: '',
  end_date: '',
  start_date: '',
  created_at: '',
  updated_at: '',
};

export const EMPTY_CHECK_IN_CYCLE: CheckInCycle =
{
  id: 0,
  client_id: 0,
  evaluation_period_id: 0,
  active: false,
  end_date: '',
  start_date: '',
  label: '',
  created_at: '',
  updated_at: '',
  review_window_start_date: '',
  review_window_end_date: '',
};

export const EMPTY_CLIENT: Client =
{
  id: 0,
  name: '',
  logo: '',
  gsuite_id: '',
  created_at: '',
  updated_at: '',
};

export interface IClientStore
{
  client: Client;
  evaluationPeriod: EvaluationPeriod;
  checkInCycle: CheckInCycle;
  users: Array<User>;

  createUser(user: UserCreate): Promise<User>;
  deleteUser(userId: number): Promise<void>;
  editUser(userId: number, user: UserEdit): Promise<User>;
  exportUsers(): Promise<void>;
  fetchClientDetails(clientId: number): Promise<void>;
  fetchTimeFrames(): Promise<void>;
  fetchUsers(): Promise<void>;
  getImportTemplate(): Promise<void>;
  importUsers(formData: FormData): Promise<UserResponse | undefined>;
  setCheckInCycle(checkInCycle: CheckInCycle): void;
  setEvaluationPeriod(evaluationPeriod: EvaluationPeriod): void;
  getApplicationUsage(): Promise<ApplicationUsage[]>;
  downloadApplicationUsage(): Promise<void>;

  reset(): void;
}

export default class ClientStore implements IClientStore
{
  stores: RootStore;

  client = EMPTY_CLIENT;
  evaluationPeriod = EMPTY_EVALUATION_PERIOD;
  checkInCycle = EMPTY_CHECK_IN_CYCLE;
  users = [EMPTY_USER];

  constructor(stores: RootStore)
  {
    this.stores = stores;
  }

  async fetchClientDetails(clientId: number)
  {
    this.stores.loadingStore.start(FETCH_CLIENT_DETAILS);

    try
    {
      const clientDetails = await getClientDetails(clientId);
      setClient(clientDetails);

      runInAction(() => {
        this.client = clientDetails;
      });
    }

    catch (error)
    {
      console.warn(error);
    }

    finally
    {
      this.stores.loadingStore.end(FETCH_CLIENT_DETAILS);
    }
  }

  async fetchTimeFrames()
  {
    this.stores.loadingStore.start(FETCH_CURRENT_TIME_FRAMES);

    try
    {
      const client = getClient() || EMPTY_CLIENT;
      const {
        current_evaluation_period,
        current_check_in_cycle,
      } = await getTimeFrames(client.id);

      runInAction(() => {
        if (this.evaluationPeriod.id === EMPTY_EVALUATION_PERIOD.id)
        {
          this.evaluationPeriod = current_evaluation_period;
        }

        if (this.checkInCycle.id === EMPTY_CHECK_IN_CYCLE.id)
        {
          this.checkInCycle = current_check_in_cycle;
        }
      });
    }

    catch (error)
    {
      console.warn(error);
    }

    finally
    {
      this.stores.loadingStore.end(FETCH_CURRENT_TIME_FRAMES);
    }
  }

  async fetchUsers()
  {
    this.stores.loadingStore.start(FETCH_USERS);

    try
    {
      const client = getClient() || EMPTY_CLIENT;
      const users = await getUsers(client.id);

      runInAction(() => {
        this.users = users;
      })
    }

    catch (error)
    {
      console.warn(error);
    }

    finally
    {
      this.stores.loadingStore.end(FETCH_USERS);
    }
  }

  async createUser(
    user: UserCreate
  ): Promise<User> {
    this.stores.loadingStore.start(CREATE_USER);

    try
    {
      const client = getClient() || EMPTY_CLIENT;

      user.client_id = client.id;

      const created = await createUser(user)

      this.stores.notificationStore.triggerSuccessNotification(`${created.name} was created`);

      return created;
    }

    catch(error)
    {
      this.stores.notificationStore.triggerErrorNotification("User was not created");
      console.warn(error);
    }

    finally
    {
      this.stores.loadingStore.end(CREATE_USER);
    }

    return EMPTY_USER
  }

  async editUser(
    userId: number,
    user: UserEdit
  ): Promise<User> {
    this.stores.loadingStore.start(EDIT_USER);

    try
    {
      const client = getClient() || EMPTY_CLIENT;

      user.client_id = client.id;

      const edited = await editUser(userId, user);

      this.stores.notificationStore.triggerSuccessNotification(`${edited.name} was updated.`);

      return edited;
    }

    catch (error)
    {
      this.stores.notificationStore.triggerErrorNotification('User was not updated.');
    }

    finally
    {
      this.stores.loadingStore.end(EDIT_USER);
    }

    return EMPTY_USER;
  }

  async deleteUser(userId: number)
  {
    this.stores.loadingStore.start(DELETE_USER);

    try
    {
      await api.delete(`users/${userId}`);
      this.stores.notificationStore.triggerSuccessNotification(
        "User deleted successfully"
      );
    }

    catch (error)
    {
      console.warn(error);
    }

    finally
    {
      this.stores.loadingStore.end(DELETE_USER);
    }
  }

  async exportUsers()
  {
    this.stores.loadingStore.start(EXPORT_USERS);

    try
    {
      const client = getClient() || EMPTY_CLIENT;
      const { data, headers } = await exportUsers(client.id);
      const filename = getFileName(headers);
      saveAs(data, filename);
    }

    catch (error)
    {
      this.stores.notificationStore.triggerErrorNotification(
        "An error occurred while exporting."
      );

      console.warn(error);
    }

    finally
    {
      this.stores.loadingStore.end(EXPORT_USERS);
    }
  }

  async importUsers(
    formData: FormData
  ): Promise<UserResponse | undefined> {
    this.stores.loadingStore.start(IMPORT_USERS);

    try
    {
      const client = getClient() || EMPTY_CLIENT;
      const users = await importUsers(client.id, formData);
      this.stores.notificationStore.triggerSuccessNotification(
        'Users were imported successfully!'
      )
      return users;
    }

    catch (error)
    {
      this.stores.notificationStore.triggerErrorNotification(
        'An error occurred while importing.'
      );
      console.warn(error);
    }

    finally
    {
      this.stores.loadingStore.end(IMPORT_USERS);
    }
  }

  async getImportTemplate()
  {
    const { data, headers } = await getImportTemplate();
    const filename = getFileName(headers);
    saveAs(data, filename);
  }

  async getApplicationUsage()
  {
    try
    {
      this.stores.loadingStore.start(APPLICATION_USAGE);
      const { data } = await api.post('reports/application-usage');
      return data
    }

    catch (error)
    {
      this.stores.notificationStore.triggerErrorNotification(
        'Could not retrieve data'
      );
    }

    finally
    {
      this.stores.loadingStore.end(APPLICATION_USAGE);
    }

    return [];
  }

  async downloadApplicationUsage()
  {
    try
    {
      this.stores.loadingStore.start(APPLICATION_USAGE_DOWNLOAD);
      const { data, headers } = await downloadApplicationUsage();
      const filename = getFileName(headers);
      saveAs(data, filename);
    }

    catch (error)
    {
      this.stores.notificationStore.triggerErrorNotification(
        'An error occurred while downloading the report'
      );
    }

    finally
    {
      this.stores.loadingStore.end(APPLICATION_USAGE_DOWNLOAD);
    }
  }

  reset()
  {
    this.client = EMPTY_CLIENT;
    this.evaluationPeriod = EMPTY_EVALUATION_PERIOD;
    this.checkInCycle = EMPTY_CHECK_IN_CYCLE;
    clearClientData();
  }

  setCheckInCycle(checkInCycle: CheckInCycle)
  {
    const { userStore } = this.stores;

    runInAction(() => {
      this.checkInCycle = checkInCycle;
      userStore.init(userStore.user);
    });
  }

  setEvaluationPeriod(evaluationPeriod: EvaluationPeriod)
  {
    const { userStore } = this.stores;
    const cycles = this.stores.evaluationPeriodStore.checkInCycles
      .filter(cycle => cycle.evaluation_period_id === evaluationPeriod.id);

    runInAction(() => {
      this.evaluationPeriod = evaluationPeriod;
      this.checkInCycle = cycles.find(cycle => cycle.active === true)
        || cycles[0];
      userStore.init(userStore.user);
    });
  }
}

decorate(ClientStore, {
  client: observable,
  evaluationPeriod: observable,
  checkInCycle: observable,

  fetchClientDetails: action,
  fetchTimeFrames: action,
  reset: action,
});

async function getClientDetails(clientId: number): Promise<ClientResponse>
{
  const response = await api.get(`clients/${clientId}`);
  return response.data;
}

async function getTimeFrames(
  clientId: number,
): Promise<TimeFramesResponse> {
  const response = await api.get(`clients/${clientId}/time-frames`, {});
  return response.data;
}

async function getUsers(
  clientId: number
): Promise<UserResponse> {
  const { data } = await api.get(`clients/${clientId}/users`, {});
  return data
}

async function createUser(
  user: UserCreate
): Promise<CreateUserResponse> {
  const { data } = await api.post('users', user);
  return data;
}

async function editUser(
  userId: number,
  user: UserEdit
): Promise<EditUserResponse> {
  const { data } = await api.put(`users/${userId}`, user);
  return data
}

async function exportUsers(
  clientId: number
): Promise<AxiosResponse> {
  const response: AxiosResponse = await api.get(`clients/${clientId}/export-users`, {
    responseType: 'blob',
  });

  return response;
}

async function importUsers(
  clientId: number,
  formData: FormData
): Promise<UserResponse> {
  const { data } = await api.post(`clients/${clientId}/import-users`, formData);
  return data;
}

async function getImportTemplate(): Promise<AxiosResponse>
{
  const response: AxiosResponse = await api.get('users-import-template', {
    responseType: 'blob',
  });

  return response;
}

async function downloadApplicationUsage(): Promise<AxiosResponse>
{
  const response: AxiosResponse = await api.post(
    'reports/application-usage/download',
    {},
    { responseType: 'blob' }
  );
  return response;
}

type ClientResponse = Client;

type TimeFramesResponse =
{
  current_evaluation_period: EvaluationPeriod;
  current_check_in_cycle: CheckInCycle;
};

type UserResponse = Array<User>;

type CreateUserResponse = User;

type EditUserResponse = User;
